import React, { useState } from "react";
import Iframe from "react-iframe";
import "react-phone-number-input/style.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PhoneInput from "react-phone-number-input";
import Form from "react-bootstrap/Form";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa6";
import { Link } from "react-router-dom";
import * as queryData from "../../services/dataServices";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import Helmet from "../../components/Helmet";
import { useMutation, useQuery } from "react-query";
import { getPageData, getPageData1 } from "../../services/dataServices";
import BannerImage from "../../components/BannerImage";
import Image from "../../components/Image"
export const enquirySchema = Yup.object({
  firstName: Yup.string().required("Please enter name"),
  email: Yup.string().email().required("Please enter  email address"),
  phone: Yup.string().required("Please enter phone number"),

  enqId: Yup.number().required("Please select concern"),
  message: Yup.string().required("Please enter message"),
});

function ContactUs({ isPreview, userId, sectionId }) {
  const [isBannerLoading, setIsBannerLoading] = useState(true);
  const pageCode = "VCONTACTUS";
  const pagesSectionData1 = useQuery(
    ["pagesSectionData1", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );
  // const handleError = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  
  const { isSuccess } = pagesSectionData1;
  const loading=pagesSectionData1.isLoading;

  const bannerImage =
    pagesSectionData1?.data?.sectionData?.filter(
      (item) =>
        item.section_code === "CONTACTUS" &&
        item.field_label_code === "BANNERIMAGE"
    )[0]?.psdd_data ?? "";

  const pagesSectionData = useQuery(
    ["pagesSectionData", pageCode, isPreview, userId, sectionId],
    () => getPageData(pageCode, isPreview, userId, sectionId)
  );

  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [error, setErrors] = useState({});
  const [isFocusedEmail, setIsFocusedEmail] = useState(false);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputFocus = () => {
    setIsFocused(true);
  };
  const handleInputBlur = () => {
    setIsFocused(false);
  };
  const handleInputFocusEmail = () => {
    setIsFocusedEmail(true);
  };
  const handleInputBlurEmail = () => {
    setIsFocusedEmail(false);
  };
  const keywords = pagesSectionData?.data?.data[0]?.meta_keyword;
  const slug = pagesSectionData?.data?.data[0]?.slug;
  const meta_title = pagesSectionData?.data?.data[0]?.meta_title;
  const meta_description = pagesSectionData?.data?.data[0]?.meta_description;

  const handlePhoneChange = (e) => {
    setFieldValue("phone", e);
  };
  const getAllResorts = useQuery("Resorts", queryData.getAllResorts);

  const brandResortId = getAllResorts?.data?.data?.find(
    (resort) => resort.resort_code === "95216"
  )?.brand_resort_id;
  const initialValues = {
    firstName: "",
    email: "",
    lastName:" ",
    phone: "",
    resortId: brandResortId,
    enqId: "",
    message: "",
  };
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: enquirySchema,
    onSubmit: (values, action) => {
      // const formData = new FormData();
      // formData.append("name", values.name);
      // formData.append("email", values.email);
      // formData.append("phone", values.phone);
      // formData.append("resortId", values.resortId);
      // formData.append("enquiryTypeId", values.enquiryTypeId);
      // formData.append("message", values.message);

      addnewEnquiryMutation.mutate(values);
      action.resetForm();
    },
  });

  const addnewEnquiryMutation = useMutation(queryData.insertEnquiry, {
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);

      toast.success(data.message);
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });


  

  const getAllEnquiryTypes = useQuery(
    "EnquiryTypes",
    queryData.getAllEnquiryTypes
  );
  // if (loading) {
  //   setIsLoadingMain(true);
  //   return;
  // }
  // if (isSuccess) {
  //   setIsLoadingMain(false);
  // }
  // const handleImageLoad = () => { setIsLoadingMain(false) };

  // const handleImageLoad = () => {
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // if (isLoading) {
  //   setIsLoadingMain(true);
  //   return <Loader />;
  // }
  // else if (isBannerLoading) {
  //   setIsLoadingMain(true);
  // }
  // const handleImageLoad = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };

  // if (loading) {
  //   setIsLoadingMain(true);
  //   document.body.classList.add('slider-open');
  //   return <Loader />;
  // }
  // else if (isBannerLoading) {
  //   document.body.classList.add('slider-open');
  //   setIsLoadingMain(true);
  // }
  // if (!getAllResorts.data) {
  //   return 
  // }
  // if (!getAllEnquiryTypes.data) {
  //   return 
  // }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // const getInTouchPara =
  //   "If you have any queries, suggestions, or requests relating to Atmosphere Hotels & Resorts services and experiences, please use the form below to let us know. We shall get back to you at the earliest possible.";
  const conditonText = "I confirm I have read and given consent for the above.";
  const manageContactUs = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "CONTACTUS"
      )[0]
  );

  const bannerImageAltTag =
    manageContactUs?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "BANNERIMAGE"
    )[0]?.img_alt_tag ?? "";

  const getInTouch =
    manageContactUs?.[0]?.subSectionData?.filter(
      (item) => item.sub_section_title_code === "GETINTOUCH"
    )[0] ?? "";

  const getInTouchIcon =
    getInTouch?.elements?.filter((item) => item.field_title_code === "ICON")[0]
      ?.data ?? "";
  const getInTouchHeading =
    getInTouch?.elements?.filter((item) => item.field_title_code === "TITLE")[0]
      ?.data ?? "";
  const getInTouchPara =
    getInTouch?.elements?.filter(
      (item) => item.field_title_code === "DESCRIPTION"
    )[0]?.data ?? "";

  const contactDetails =
    manageContactUs?.[0]?.subSectionData?.filter(
      (item) => item.sub_section_title_code === "CONTACTDETAILS"
    )[0] ?? "";
  const locationData =
    contactDetails?.elements?.filter(
      (item) => item.field_title_code === "LOCATION"
    )[0]?.data ?? "";
  const phoneNoData =
    contactDetails?.elements?.filter(
      (item) => item.field_title_code === "PHONE"
    )[0]?.data ?? "";
  const emailIdData =
    contactDetails?.elements?.filter(
      (item) => item.field_title_code === "EMAIL"
    )[0]?.data ?? "";

  const followUsData =
    manageContactUs?.[0]?.subSectionData?.filter(
      (item) => item.sub_section_title_code === "FOLLOWUS"
    )[0] ?? "";
  const contactUsHeading =
    followUsData?.elements?.filter(
      (item) => item.field_title_code === "HEADING"
    )[0]?.data ?? "";
  const facebookUrl =
    followUsData?.elements?.filter(
      (item) => item.field_title_code === "FACEBOOK"
    )[0]?.data ?? "";
  const linkedInUrl =
    followUsData?.elements?.filter(
      (item) => item.field_title_code === "LINKEDIN"
    )[0]?.data ?? "";
  const instaGramUrl =
    followUsData?.elements?.filter(
      (item) => item.field_title_code === "INSTAGRAM"
    )[0]?.data ?? "";

  return (
    <>
      <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100 cover-overlay">
          <BannerImage
              className="w-100 h-100"
              src={bannerImage}
              title=""
              alt={bannerImageAltTag}
              isDataIsLoading={loading}
            />
            {/* <img
              className="w-100 h-100"
              src={bannerImage}
              onLoad={handleImageLoad}
              onError={handleError}
              title=""
              alt={bannerImageAltTag}
            /> */}
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                CONTACT US
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>
      <section>
        <div className="ask-to-atmosphere section-padding pb-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="ask-form text-center">
                  <div className="heading-icon mb-xl-4 mb-2 pt-2 pb-2">
                    {/* <img src={getInTouchIcon} title="" alt="" /> */}
                    <Image className="" src={getInTouchIcon} alt={""} />
                  </div>
                  <h2 className="h1 georgia text-uppercase">
                    {getInTouchHeading}
                  </h2>
                  <p className="m-auto mt-3">{getInTouchPara}</p>

                  <form action="#" method="get" id="demoForm">
                    <div className="row mt-xl-5 mt-4">
                      <div className="col-lg-4">
                        <div className="mb-4 position-relative">
                          <input
                            className="form-control"
                            type="text"
                            value={values.firstName}
                            id="name"
                            name="firstName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleInputFocus}
                            maxLength={50}
                          />
                          <label
                            className={
                              isFocused || values.firstName
                                ? "label-focused"
                                : "form-label custom-label"
                            }
                          >
                            Your Name
                          </label>
                          {errors.firstName && touched.firstName ? (
                            <p className="form-error text-start text-danger">
                              {errors.firstName}
                            </p>
                          ) : error.firstName ? (
                            <p className="form-error text-start text-danger">
                              {error.firstName}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {/* /col-lg-4 */}
                      <div className="col-lg-4">
                        <div className="mb-4 position-relative">
                          <input
                            className="form-control"
                            type="email"
                            value={values.email}
                            id="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleInputFocusEmail}
                          />
                          <label
                            className={
                              isFocusedEmail || values.email
                                ? "label-focused"
                                : "form-label custom-label"
                            }
                          >
                            Email Address
                          </label>
                          {errors.email && touched.email ? (
                            <p className="form-error text-start text-danger">
                              {errors.email}
                            </p>
                          ) : error.email ? (
                            <p className="form-error text-start text-danger">
                              {error.email}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* /mb-4 */}
                      </div>
                      {/* /col-lg-4 */}
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <PhoneInput
                            international
                            defaultCountry=""
                            value={values.phone}
                            onChange={handlePhoneChange}
                            onBlur={handleBlur}
                            id="phone"
                            name="phone"
                            className="form-control"
                            placeholder="Phone Number"
                            limitMaxLength={20}
                          />
                          {errors.phone && touched.phone ? (
                            <p className="form-error text-start text-danger">
                              {errors.phone}
                            </p>
                          ) : error.phone ? (
                            <p className="form-error text-start text-danger">
                              {error.phone}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* /mb-4 */}
                      </div>
                      {/* /col-lg-4 */}
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <select
                            name="enqId"
                            id="enqId"
                            className="form-control form-select"
                            aria-label="Default select example"
                            onChange={handleChange}
                            value={values.enqId}
                          >
                            <option disabled value="">
                              What can we help you with
                            </option>
                            {getAllEnquiryTypes?.data?.data?.map((option) => (
                              <option
                                key={option.enquiry_type_id}
                                value={option.enquiry_type_id}
                              >
                                {option.enquiry_type}
                              </option>
                            ))}
                          </select>

                          {errors.enqId && touched.enqId ? (
                            <p className="form-error text-start text-danger">
                              {errors.enqId}
                            </p>
                          ) : error.enqId ? (
                            <p className="form-error text-start text-danger">
                              {error.enqId}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* /mb-4 */}
                      </div>
                      {/* /col-lg-4 */}
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <Form.Control
                            value={values.message}
                            name="message"
                            id="message"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Your message here..."
                            as="textarea"
                            rows={4}
                          />
                          {errors.message && touched.message ? (
                            <p className="form-error text-start text-danger">
                              {errors.message}
                            </p>
                          ) : error.message ? (
                            <p className="form-error text-start text-danger">
                              {error.message}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* /mb-4 */}
                      </div>
                      {/* /col-lg-4 */}
                      <div className="gdpr-compliance text-start">
                        <span className="mb-2 d-flex">GDPR Compliance</span>
                        <p className="m-0">
                          By giving your confirmation you explicitly give
                          consent for us to store and use this information to
                          service your requests.
                        </p>
                        <p>
                          If you do not consent we will not store any personal
                          information and will only send an email with the
                          relevant details in order to service your requests.
                        </p>
                      </div>
                      <div className="bottom-actions pt-lg-3 d-flex justify-content-between align-items-center">
                        <div className="check-terms text-start">
                          <Form.Label
                            for="CheckTerms"
                            className="d-flex position-relative pointer-event"
                          >
                            <Form.Control
                              type="checkbox"
                              id="CheckTerms"
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                            />
                            <div className="custom-check"></div>
                            <span>{conditonText}</span>
                          </Form.Label>
                        </div>
                        <div className="form-submit">
                          <input
                            onClick={handleSubmit}
                            type="submit"
                            value="Submit"
                            className={`btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2`}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /row */}
                  </form>
                </div>
                {/* /ask-form */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="contact-via section-padding">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="contact-card bg-white text-center">
                  <h5 className="text-uppercase georgia">LOCATE US</h5>
                  <div className="content-text mt-4">
                    <p>{locationData}</p>
                  </div>
                </div>
              </div>
              {/* /col-lg-4 */}
              <div className="col-lg-4 col-md-6">
                <div className="contact-card bg-white text-center">
                  <h5 className="text-uppercase georgia">CALL US</h5>
                  <div className="content-text d-flex align-items-center justify-content-center mt-4">
                    <Link to="tel:+919876543218">{phoneNoData}</Link>
                  </div>
                </div>
              </div>
              {/* /col-lg-4 */}
              <div className="col-lg-4 col-md-6">
                <div className="contact-card bg-white text-center">
                  <h5 className="text-uppercase georgia">EMAIL US</h5>
                  <div className="content-text d-flex align-items-center justify-content-center mt-4">
                    <Link to={`mailto:${emailIdData}`}>{emailIdData}</Link>
                  </div>
                </div>
              </div>
              {/* /col-lg-4 */}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="atmosphere-social-links section-padding pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="dashed-heading position-relative">
                  <h3 className="mb-0 georgia text-uppercase text-center">
                    <span className="m-auto px-4">{contactUsHeading}</span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="row mt-lg-5 mt-4">
              <div className="contact-via-social d-flex justify-content-center">
                <Link
                  // to="https://facebook.com/atmospherecore"
                  to={`${facebookUrl}`}
                  target="_blank"
                >
                  <FaFacebookF className="icon-font" />
                </Link>
                {/* <Link to="/"><img src={TwitterX} alt="Twitter" title="Twitter"/></Link> */}
                <Link
                  // to="https://www.linkedin.com/company/atmospherecore?originalSubdomain=mv"
                  to={`${linkedInUrl}`}
                  target="_blank"
                >
                  <FaLinkedinIn className="icon-font" />
                </Link>
                <Link
                  // to="https://www.instagram.com/atmospherecore"
                  to={`${instaGramUrl}`}
                  target="_blank"
                >
                  <FaInstagram className="icon-font" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="atmosphere-map-location section-padding d-none">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="dashed-heading position-relative">
                  <h3 className="mb-0 georgia text-uppercase">
                    <span>LOCATE US ON MAP</span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="row mt-lg-5 mt-4">
              <div className="map-location">
                <Iframe
                  url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3977.51407709048!2d73.3658826758209!3d4.500908643355437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b407314c3e6303d%3A0x9de11604132a4593!2sVARU%20by%20Atmosphere!5e0!3m2!1sen!2sin!4v1699447193525!5m2!1sen!2sin"
                  width="100%"
                  height="496"
                  id=""
                  className=""
                  display="block"
                  position="relative"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactUs;
